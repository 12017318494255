<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：系统表格信息(表体)
	开始时间：2021-04-20
	开发人员：万攀
	最后修改：2021-04-20
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="systemBox billing_table filedBox" id="nodeBaseTableBox">
    <div class="filedHead">
      <el-button size="small" type="primary" @click="showSystemtableInfo">新增</el-button>
    </div>
    <el-table row-key="id" id="systemTableSetUp" :data="bodyMsgData" height="90%" border style="width: 100%">
      <!-- 拖拽图标 -->
      <el-table-column width="50">
        <template slot-scope="scope">
          <i class="el-icon-s-grid my-handle"></i>
        </template>
      </el-table-column>
      <!-- 索引 -->
      <!-- <el-table-column type="index" width="50"></el-table-column> -->
      <!-- 操作 -->
      <el-table-column label="操作" width="80">
        <template slot-scope="scope">
          <i class="el-icon-delete icon" v-show="scope.row.system.is_update_must == 0"
            @click="deleteFiledData(scope.$index,scope.row)"></i>
        </template>
      </el-table-column>
      <!-- 数据内容 -->
      <el-table-column prop="system.name" label="列名" sortable width="200"></el-table-column>
      <el-table-column label="显示名称" width="300">
        <template slot-scope="scope">
          <el-input size="mini" v-model="scope.row.show_name" @blur="updateName(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="列宽" width="100">
        <template slot-scope="scope">
          <el-input size="mini" v-model="scope.row.col_custom_width" @blur="updateName(scope.row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="是否显示" width="120">
        <template slot-scope="scope">
          <el-checkbox :value="scope.row.is_enable == 1" @change="updateName(scope.row,'is_enable')"
            :disabled="scope.row.system.is_update_must == 1">显示</el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="必填" width="120">
        <template slot-scope="scope">
          <el-checkbox :value="scope.row.is_must == 1" @change="updateName(scope.row,'is_must')"
            :disabled="scope.row.system.is_update_must == 1"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="打印显示" width="120">
        <template slot-scope="scope">
          <el-checkbox :value="scope.row.is_print == 1" @change="updateName(scope.row,'is_print')"></el-checkbox>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="create_time" label="创建时间" width="250"></el-table-column>
      <el-table-column prop="update_time" label="更新时间" width="250"></el-table-column>
      <el-table-column prop="type" label="类型" width="120"></el-table-column> -->
      <el-table-column prop="bz" label="备注" min-width="12%"></el-table-column>
    </el-table>

    <!-- 系统级表体信息弹框 -->
    <el-dialog title="新增表体信息" :visible.sync="show_systemTableBox" :before-close="close_box" append-to-body>
      <div class="billing_table">
        <el-table @select="selectFormData" @select-all="selectAllTableData" ref="baseTable" :data="tableBaseData" border
          height="500px">
          <el-table-column type="selection" width="55" :selectable="judgeDisable" align="center"></el-table-column>
          <el-table-column property="name" label="名称" sortable width="200" align="center"></el-table-column>
          <el-table-column property="create_time" label="创建时间" width="300" align="center"></el-table-column>
          <el-table-column property="update_time" label="更新时间" min-width="300" align="center"></el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close_box">取 消</el-button>
        <el-button type="primary" @click="saveSelectData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  //导入请求方法
  import api from "@/network/system/workflowSetup";

  //引入拖动排序
  import Sortable from "sortablejs";

  // 导入vuex
  import {
    mapMutations,
    mapState
  } from 'vuex'

  export default {
    name: "systemTableSetup",
    props: {
      //节点信息
      nodeMsg: {
        type: Object,
        default: {
          currentNodeType: {}, //当前所选节点分类数据
          nodeId: -1, //节点id
        }
      },
      //操作类型
      operateType: {
        type: Number,
        default: 0
      },
      templeTableBaseInfo: {
        type: Array,
        default () {
          return []
        }
      },
      //回显的数据
      nodeSystemTableData: {
        type: Array,
        default () {
          return []
        }
      },
    },
    data() {
      return {
        //系统级数据
        tableBaseData: [], //系统表体数据
        systemSelData: [], //系统级已选数据
        systemSelIdArr: [], //已经选中的系统级id数组

        //企业级数据
        bodyMsgData: [], //保存到页面上的数据

        //控制开关
        show_systemTableBox: false, //控制显示系统级弹框数据
      }
    },
    created() {
      //获取节点系统级表格数据
      this.getSystemTableBaseInfo();
    },
    mounted() {
      this.initDrag(); //初始化排序
    },
    computed: {
      ...mapState({
        NodeClassfiyData: state => state.system.NodeClassfiyData, //节点分类id
        show_billsCreateBox: state => state.billing.show_billsCreateBox, //控制显示新建弹框组件
        ControlCommitNode: state => state.system.ControlCommitNode, //控制是否提交当前节点信息数据
      })
    },
    watch: {
      // 监听是否提交当前节点信息
      ControlCommitNode(newVal) {
        if (newVal) {
          //提交当前组件数据
          this.commitNodeTableData();
        }
      },
      //监听回显数据发生变化
      nodeSystemTableData(newVal) {
        this.bodyMsgData = newVal;
      },
    },
    methods: {
      /* 查询表体的系统数据*/
      getSystemTableBaseInfo() {
        let data = {
          type_id: this.nodeMsg.currentNodeType.id //节点分类id
        }
        //发送请求
        api.getSystemTableBaseInfo(data).then(res => {
          if (res.code == 200) {
            this.tableBaseData = res.data
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 显示系统级字段数据 */
      showSystemtableInfo() {
        //显示弹框
        this.show_systemTableBox = true;
        //循环前清空
        this.systemSelIdArr = []
        //循环获取已选数据
        this.bodyMsgData.forEach((item, index) => {
          this.systemSelIdArr.push(item.system_tableid);
        })
        //获取默认已选中数据
        this.tableBaseData.forEach((item, index) => {
          // 根据id判断已选信息
          if (this.systemSelIdArr.includes(item.id)) {
            this.$nextTick(() => {
              this.$refs.baseTable.toggleRowSelection(item, true);
            })
          } else {
            this.$nextTick(() => {
              this.$refs.baseTable.toggleRowSelection(item, false);
            })
          }
        })
      },

      /* 判断系统级数据是否可以勾选 */
      judgeDisable(row) {
        return !this.systemSelIdArr.includes(row.id);
      },

      /* 保存选中基本信息数据(系统级)  */
      selectFormData(selection, row) {
        this.systemSelData = selection;
      },

      /* 弹框全选(系统级) */
      selectAllTableData(selection) {
        this.systemSelData = selection;
      },

      /* 渲染选中的基本信息数据 */
      saveSelectData() {
        //获取未添加的数据
        let seledSystemIdArr = []; //定义存储已选系统级数据
        //循环获取已选系统级数据
        this.bodyMsgData.forEach((item, index) => {
          seledSystemIdArr.push(item.system_tableid);
        })
        let newSelSystemData = []; //新添加的系统级数据
        //将选中数据加载到界面,并获取默认数据
        this.systemSelData.forEach((item, index) => {
          //已经添加的数据不重复添加
          if (!seledSystemIdArr.includes(item.id)) {
            let itemBaseInfo = {
              system_tableid: item.id, //表单id
              show_name: item.name, //显示名称
              is_must: item.is_update_must, //是否必填
              is_enable: 1, //是否启用
              is_print : item.is_update_must,//是否打印时显示
              col_custom_width: 100, //默认宽度
              system: item //系统级数据
            }
            //获取新选择的数据
            newSelSystemData.push(itemBaseInfo);
          }
        })
        if (this.operateType == 0) { //新增添加信息
          //循环添加新选择的数据
          newSelSystemData.forEach((item, index) => {
            this.bodyMsgData.push(item);
          })
        } else if (this.operateType == 1) { //修改时新增信息
          //判断是否有新增数据
          if (newSelSystemData.length > 0) {
            // 循环去除系统信息
            newSelSystemData.forEach((item, index) => {
              //删除系统数据
              delete item.system;
              //增加节点id
              item.status_id = this.nodeMsg.nodeId
            })
            //定义后端接受参数
            let addData = {
              sysTables: JSON.stringify(newSelSystemData)
            }
            //加载loading框
            this.loading = this.commonJsExtend.customLoading("#nodeBaseTableBox", 4, '表体信息添加中,请稍候...');
            //发送请求
            api.addNodeSysTable(addData).then(res => {
              this.loading.close();
              if (res.code == 200) {
                //获取更新后信息
                this.getNodeTableByNodeId();
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          }
        }
        //关闭弹框
        this.show_systemTableBox = false;
      },

      /* 已选信息编辑时触发(修改界面使用) */
      updateName(data, fileName) {
        //处理复选框
        data[fileName] == 0 ? data[fileName] = 1 : data[fileName] = 0
        //判断当前界面是新增还是修改
        if (this.operateType == 1) { //修改
          let updateData = {
            id: data.id,
            is_enable: data.is_enable, //是否启用
            is_must: data.is_must, //是否必填
            is_print:data.is_print, //是否在打印界面显示
            show_name: data.show_name, //显示名称
            col_custom_width: data.col_custom_width, //列宽
          }
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeBaseTableBox", 4, '表体信息修改中,请稍候...');
          //发送请求
          api.updateNodeSysTable(updateData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //获取更新后信息
              this.getNodeTableByNodeId();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 根据节点id查询表格基本信息(修改界面使用) */
      getNodeTableByNodeId() {
        let data = {
          status_id: this.nodeMsg.nodeId
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#nodeBaseTableBox", 4, '信息更新中,请稍候...');
        api.findAllNodeSysTable(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //获取界面信息
            this.bodyMsgData = res.data;
          } else {
            this.$message({
              type: '',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 删除已选信息 */
      deleteFiledData(index, data) {
        //判断当前在哪个界面
        if (this.operateType == 0) { //新增
          //根据下标删除
          this.bodyMsgData.splice(index, 1);
        } else if (this.operateType == 1) { //修改
          //定义后端接受数据
          let deleteData = {
            id: data.id
          }
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeBaseTableBox", 4, '信息删除中,请稍候...');
          //发送请求
          api.deleteNodeSysTable(deleteData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              //获取更新后信息
              this.getNodeTableByNodeId();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 提交节点表格所选信息 */
      commitNodeTableData() {
        //验证数据合法性
        //定义后端接受数据
        let data = JSON.parse(JSON.stringify(this.bodyMsgData))
        data.forEach((item, index) => {
          delete item.system
        })
        //提交数据到父组件
        this.$emit("getNodeTableData", data);
      },

      close_box() {
        this.show_systemTableBox = false;
      },

      /* 初始化表格行拖动功能*/
      initDrag() {
        //若为新增则不发送请求
        if (this.operateType == 0) {
          return
        }
        //初始化需要拖动的容器
        const el = document.querySelectorAll("#systemTableSetUp>.el-table__body-wrapper > table > tbody")[0];
        Sortable.create(el, {
          disabled: false, // 拖拽不可用? false启用
          // ghostClass: 'sortable-ghost', //拖拽样式
          // chosenClass: "chosen",
          // dragClass: "chosen",
          animation: 150, // 拖拽延时，效果更好看
          handle: ".my-handle",
          onEnd: (e) => { // 拖拽结束时的触发
            // let arr = this.CustomerFollowupWay; // 获取表数据
            let data = {
              sourceId: this.bodyMsgData[e.oldIndex].id,
              targetId: this.bodyMsgData[e.newIndex].id,
              status_id: this.nodeMsg.nodeId
            }
            this.loading = this.commonJsExtend.customLoading("#systemTableSetUp", 4, '排序更新中,请稍候...');
            api.updateSysNodeSysTableSort(data).then(res => {
              this.loading.close();
              if (res.code == 200) {
                //获取更新后信息
                this.getNodeTableByNodeId();
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          },
        });
      },
    },
  }
</script>

<style lang="less">
  // 节点表格样式
  .nodeTable {
    height: 100%;
    // border: 1px solid black;
  }
</style>
