<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：自定义表单信息(抬头)
	开始时间：2021-04-20
	开发人员：万攀,康泽鑫
	最后修改：2021-07-07
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="filedBox">
    <!-- 新增按钮 -->
    <div class="filedHead">
      <el-button type="primary" size="mini" @click="show_addFileBox">新增</el-button>
    </div>
    <!-- 自定义信息表格 -->
    <div class="filedBody billing_table">
      <el-table :data="filedData" height="100%" border style="width: 100%">
        <!-- 索引 -->
        <el-table-column type="index" width="50"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <i class="el-icon-delete icon" @click="deleteFiledData(scope.$index,scope.row)"></i>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column prop="name" label="名称" sortable width="180">
          <template slot-scope="scope">
            <el-input v-model="scope.row.name" size="mini" @input="updateName(scope.row)"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="类型" width="120">
          <template slot-scope="scope">
            <template v-for="item in filedTypeDate">
              <span v-if='scope.row.type==item.id'>{{item.name}}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="120">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.is_enable" @change="updateName(scope.row)">启用</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="必填" width="120">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.is_must" @change="updateName(scope.row)"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="预览" min-width="12%">
          <template slot-scope="scope">
            <div class="filedPriviewBox">
              <span class="priviewTitle">{{scope.row.name}}:</span>
              <!-- 文本输入框样式 -->
              <el-input v-if="scope.row.type==0" class="priviewInput" v-model="scope.row.value_text" size="mini"
                placeholder="请输入内容">
              </el-input>
              <!-- 下拉框 -->
              <el-select v-if="scope.row.type==1" v-model="scope.row.value_text" placeholder="请选择内容" size="mini">
                <el-option v-for="elem in scope.row.type_value" :label="elem.value" :value="elem.value"></el-option>
              </el-select>
              <!-- 单选按钮 -->
              <div style="padding-bottom: 1px;">
                <el-radio v-if="scope.row.type==2" v-model="scope.row.value_text" v-for="elem in scope.row.type_value"
                  :label="elem.value">{{elem.value}}</el-radio>
              </div>
              <!-- 多选按钮样式 -->
              <el-checkbox-group v-if="scope.row.type==3" v-model="scope.row.checkbox_val" @change="handle">
                <el-checkbox v-for="elem in scope.row.type_value" :label="elem.value"></el-checkbox>
              </el-checkbox-group>
              <!-- 日期框 -->
              <el-date-picker v-if="scope.row.type==4" type="date" placeholder="选择日期" v-model="scope.row.value_text"
                size="mini"></el-date-picker>
              <!-- 数字输入框样式 -->
              <el-input-number v-if="scope.row.type==5" class="priviewInput" v-model="scope.row.value_text" :min="0"
                size="mini" placeholder="请输入内容" controls-position="right">
              </el-input-number>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 新增自定义字段 -->
    <el-dialog title="新增自定义" :visible.sync="filedDialog" width="25%" :before-close="closeBox" append-to-body>
      <!-- 自定义信息表单 -->
      <div class="nodeAddFiledBox">
        <ul>
          <li>
            <span class="nodeTitle">名称:</span>
            <el-input class="nodeInput" size="small" v-model="filedItem.name" placeholder="请输入内容"></el-input>
          </li>
          <li>
            <span class="nodeTitle">类型:</span>
            <el-select class="nodeInput" size="small" v-model="filedItem.type" placeholder="请选择" @change="getTypeData">
              <el-option v-for="elem in filedTypeDate" :key="elem.id" :label="elem.name" :value="elem.id"></el-option>
            </el-select>
          </li>
          <li>
            <span class="nodeTitle">列宽:</span>
            <el-input class="nodeInput" size="small" v-model="filedItem.col_width" placeholder="请输入内容" maxlength="3"
              show-word-limit oninput="this.value=this.value.replace(/[^0-9]/g,'')"></el-input>
          </li>
          <li>
            <span class="nodeTitle">是否启用:</span>
            <el-checkbox v-model="filedItem.is_enable"></el-checkbox>
            <span class="nodeTitle">是否必填:</span>
            <el-checkbox v-model="filedItem.is_must"></el-checkbox>
          </li>
          <li v-show="isshow" v-for="(elem,index) in filedItem.type_value">
            <span class="nodeTitle">选项值:</span>
            <el-input class="nodeInput" size="small" v-model="elem.value" placeholder="请输入内容"></el-input>
            <span class="el-icon-circle-plus-outline nodeIcon" @click="addFiledValue"></span>
            <span class="el-icon-remove-outline nodeIcon" v-show="index>0" @click="removeFiledValue(index)"></span>
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeBox">取 消</el-button>
        <el-button type="primary" @click="addFiledData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  //导入请求方法
  import api from "@/network/system/workflowSetup"

  // 导入vuex
  import {
    mapMutations,
    mapState
  } from 'vuex'

  export default {
    name: 'filedFormSetup',
    props: {
      // 节点信息
      nodeMsg: {
        type: Object,
        default: {
          nodeId: -1, //节点id
        }
      },
      //操作类型
      operateType: {
        type: Number,
        default: 0
      },
    },
    data() {
      return {
        isshow: false, //是否显示选项值
        //自定义类型数据
        filedTypeDate: [{
            id: 0,
            name: '输入框'
          },
          {
            id: 1,
            name: '下拉框'
          },
          {
            id: 2,
            name: '单选按钮'
          },
          {
            id: 3,
            name: '多选按钮'
          },
          {
            id: 4,
            name: '日期框'
          },
          {
            id: 5,
            name: '数量框'
          }
        ],

        filedDialog: false,

        // 自定义信息数据
        filedData: [],
        // 修改新增数据
        updateAddData: [],
        // 单个自定义信息
        filedItem: {
          name: '',
          type: '',
          value: '',
          type_id: '',
          is_enable: true,
          is_must: true,
          col_width: '',
          value_text: '',
          checkbox_val: [],
          type_value: [{
            value: ''
          }]
        },
      }
    },
    created() {
    },

    computed: {
      ...mapState({
        show_billsCreateBox: state => state.billing.show_billsCreateBox, //控制显示新建弹框组件
        ControlCommitNode: state => state.system.ControlCommitNode, //控制是否提交当前节点信息数据
      }),

    },
    watch: {
      // 监听是否提交当前节点信息
      ControlCommitNode(newVal) {
        if (newVal) {
          //提交当前组件数据
          // this.commitNodeBaseFiledData();
        }
      },
    },
    methods: {

      /* 显示新增自定义弹框 */
      show_addFileBox() {
        this.filedDialog = true
        // this.$message({
        //   type:'warning',
        //   message:'该功能暂未开放!',
        //   duration:this.elDuration
        // })
      },

      handle(val) {
        console.log(val)
      },
      
      // 新增自定义信息
      addFiledData() {
        // 判断选项值是否存在为空的情况
        if (this.filedItem.name.trim() == "") {
          this.$message({
            type: 'error',
            message: '名称不能为空!',
            duration: 1500
          })
          return
        }
        for (let i = 0; i < this.filedData.length; i++) {
          if (this.filedData[i].name == this.filedItem.name) {
            this.$message({
              type: 'error',
              message: '已有相同名称'
            })
            return
          }
        }

        let typeVals = [] //定义选项值数组
        let isPass = true
        this.filedItem.type_value.forEach(item => {
          if (typeVals.indexOf(item.value) != -1) { //当选项值存在数组中时
            isPass = false
          } else {
            typeVals.push(item.value) //将选项值存入数组
          }
        })
        if (!isPass) { //存在重复的选项值时
          this.$message({
            type: 'error',
            message: '选项值不能重复'
          })
          return
        }
        // if(this.filedItem.type != 1){
        //   if (this.filedItem.type == "") {
        //     this.$message({
        //       type: 'error',
        //       message: '类型不能为空!',
        //       duration: 1500
        //     })
        //     return
        //   }
        // }
        // 将单个自定义信息增加到自定义数据中
        this.filedData.push(this.filedItem);
        this.$set(this.filedItem, 'operation', 0)
        this.updateAddData.push(this.filedItem)
        //关闭弹框
        this.filedDialog = false;
        // 清空自定义信息
        this.filedItem = {
          name: '',
          type: '',
          value: '',
          type_id: '',
          is_enable: true,
          is_must: true,
          value_text: '',
          type_value: [{
            value: ''
          }]
        }
        this.isshow = false
      },

      /* 控制选项值显示隐藏 */
      getTypeData(value) {
        this.filedItem.type_id = value
        if (value != 0 && value != 4 && value != 5) { //下拉,单选,多选时显示
          this.isshow = true
        } else { //输入,数量,日期时隐藏
          this.isshow = false
        }
      },

      /* 获取修改数据 */
      updateName(data) {
        // if (data.hasOwnProperty('id')) {
        //   this.$set(data, 'operation', 1)
        //   for (let i = 0; i < this.updateData.length; i++) {
        //     if (this.updateData[i].name == data.name) {
        //       this.updateData.splice(i, 1);
        //       break
        //     }
        //   }
        //   this.updateData.push(data)
        // } else {
        //   for (let i = 0; i < this.updateData.length; i++) {
        //     if (this.updateData[i].name == data.name) {
        //       this.updateData.splice(i, 1);
        //       this.updateData.push(data)
        //     }
        //   }
        // }
      },
      /* 删除自定义信息*/
      deleteFiledData(index, data) {
        if (data.hasOwnProperty('id')) {
          this.$set(data, 'operation', -1)
          this.updateDelectData.push(data)
        } else {
          for (let i = 0; i < this.updateAddData.length; i++) {
            if (this.updateAddData[i].name == data.name) {
              this.updateAddData.splice(i, 1)
              break
            }
          }
        }

        this.filedData.splice(index, 1)
      },

      // 单个自定义信息
      /* 新增单个自定义信息value值*/
      addFiledValue() {
        this.filedItem.type_value.push({
          value: ''
        })
      },

      getSelectTemple(data) {
        if (data.length == 0) { //判断数组如果为空就不执行
          return
        }
        if (typeof data[0].type_value == 'object') { //解决重复监听问题
          return
        }
        this.filedData = []
        data.forEach(item => {
          if (item.is_enable == 1) {
            item.is_enable = true
          } else {
            item.is_enable = false
          }
          if (item.is_must == 1) {
            item.is_must = true
          } else {
            item.is_must = false
          }
          let arrValue = []
          let arrType = []
          arrType = item.type_value.split(",")
          arrType.forEach(item => {
            let obj = {
              value: item
            }
            arrValue.push(obj)
          })
          item.type_value = arrValue
          this.filedItem = item
          this.filedData.push(this.filedItem)
          this.filedItem = {
            name: '',
            type: '',
            value: '',
            type_id: '',
            col_width: 100,
            is_enable: true,
            is_must: true,
            value_text: '',
            type_value: [{
              value: ''
            }]
          }
        })
      },

      /* 移除单个自定义信息value值 */
      removeFiledValue(index) {
        this.filedItem.type_value.splice(index, 1)
      },

      /* 关闭新增盒子 */
      closeBox() {
        this.filedDialog = false
        this.isshow = false
        // 初始化filedItem
        Object.assign(this.$data.filedItem, this.$options.data().filedItem);
      },

      /* 提交自定义节点基本信息数据 */
      // commitNodeCusBaseData(){
      //   //验证数据合法性
      //   //提交数据
      //   this.$emit("getNodeCusBaseData",1)
      // },
    }
  }
</script>

<style lang="less">
  // 自定义预览框样式
  .filedPriviewBox {
    display: flex;

    .priviewTitle {
      width: 100px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      flex-direction: row-reverse;
      // border: 1px solid black;
    }

    .priviewInput {
      text-align: left !important;
      border: 1px solid #dcdfe6;
    }
  }

  //节点新增自定义信息弹框
  .nodeAddFiledBox {
    max-height: 30vh;
    overflow: auto;

    // border: 1px solid black;
    ul {
      li {
        display: flex;
        align-items: center;
        padding: 10px;

        .nodeTitle {
          //标题
          width: 80px;
          font-weight: bold;
          color: black;
          // border: 1px solid black;
        }

        .nodeIcon {
          //图标
          cursor: pointer;
          padding-left: 10px;
        }

        .nodeIcon:hover {
          color: @theme;
        }

        .nodeInput {
          //输入框
          width: 240px;
        }

        .nodeTextArea {
          //文本域
          width: calc(100% - 80px);
        }
      }
    }
  }
</style>
