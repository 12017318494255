<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：节点显示界面
	开始时间：2021-01-14
	开发人员：刘巍骏,万攀
	最后修改：2021-01-14
	备注说明：如需修改请联系开发人员
-->
<template>
  <!-- 节点数据内容 -->
  <div class="nodeSetupView" id="nodeList">
    <el-tabs v-model="selNodeType" tab-position="left" style="height: 100%;">
      <el-tab-pane v-for="(elem) in NodeClassfiy" :key="elem.id" :label="elem.name" :name="elem.code" v-if="isPower">
        <!-- 节点界面总框 -->
        <div class="nodeSetupBox">
          <!-- 头部按钮区域 -->
          <div class="nodeSetupHead">
            <el-button size="small" type="primary" @click="addNodeData">新增</el-button>
          </div>

          <!-- 表格内容区域 -->
          <div class="nodeSetupBody billing_table">
            <el-table id="nodeView" :data="nodeData" height="100%" border style="width: 100%">
              <!-- 索引 -->
              <el-table-column type="index" fixed width="50"></el-table-column>
              <!-- 操作 -->
              <el-table-column label="操作" fixed width="80">
                <template slot-scope="scope">
                  <div class="operate" v-show="scope.row.name != '完成'">
                    <!-- <i class="el-icon-view operate_icon"></i> -->
                    <i class="el-icon-delete operate_icon" @click="deleteNodeData(scope.row,scope.$index)"></i>
                    <i class="el-icon-edit-outline operate_icon" @click="updateNodeData(scope.row)"></i>
                  </div>
                </template>
              </el-table-column>
              <!-- 数据内容 -->
              <af-table-column prop="name" label="名称" sortable></af-table-column>
              <af-table-column prop="charge_usernames" label="授权人员"></af-table-column>
              <af-table-column prop="join_usernames" label="参与人员"></af-table-column>
              <af-table-column prop="is_recheck" label="是否复审">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.is_recheck==1?true:false" disabled></el-checkbox>
                </template>
              </af-table-column>
              <af-table-column prop="high_leval_usernames" label="复审人员">
                <template slot-scope="scope">
                  <span v-if="scope.row.is_recheck==1">{{scope.row.high_leval_usernames}}</span>
                </template>
              </af-table-column>
              <af-table-column prop="is_final_check" label="是否终审">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.is_final_check==1?true:false" disabled></el-checkbox>
                </template>
              </af-table-column>
              <af-table-column prop="final_check_usernames" label="终审人员">
                <template slot-scope="scope">
                  <span v-if="scope.row.is_final_check==1">{{scope.row.final_check_usernames}}</span>
                </template>
              </af-table-column>
              <af-table-column prop="is_against" label="是否反审">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.is_against==1?true:false" disabled></el-checkbox>
                </template>
              </af-table-column>
              <af-table-column prop="against_useridnames" label="反审人员">
                <template slot-scope="scope">
                  <span v-if="scope.row.is_against==1">{{scope.row.against_useridnames}}</span>
                </template>
              </af-table-column>
              <af-table-column prop="bzsm" label="备注说明" min-width="12%"></af-table-column>
            </el-table>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 新建节点 -->
    <nodeSetupCreate :parentNodeData="nodeData" :operateType="operateType" :nodeMsg="nodeMsg" v-if="load_creageBox">
    </nodeSetupCreate>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入请求方法
  import api from "@/network/system/workflowSetup"; //节点相关接口

  // 导入组件
  import nodeSetupCreate from './nodeSetup_create.vue'; //新建节点组件

  export default {
    name: "nodeSetupView",
    data() {
      return {
        isPower: false, //是否有查询权限

        //当前组件数据
        selNodeType: 'SYS-NODE-TYPE-001', // 标签页下标
        nodeData: [], //当前节点分类列表数据

        //传入子组件数据
        operateType: -1, //操作类型(0: 新建 1:修改  2:查看)
        nodeMsg: { //节点信息
          currentNodeType: {}, //当前所选节点分类数据
          nodeId: '', //节点id
        },

        load_creageBox: false, //

        // loading框
        loading: '', //loading加载框
      }
    },
    mounted() {
      //获取需要存储的vuex数据
      this.judgeNoderelveData();
    },
    watch: {
      //监听选中的tab页面
      selNodeType(newVal) {
        //根据节点分类获取节点列表数据
        this.getNodeListByType(newVal);
      },
      //监听新建弹框是否显示
      show_billsCreateBox(newVal) {
        if (!newVal) { //关闭时
          //获取一次列表信息
          this.getNodeListByType(this.nodeMsg.currentNodeType.code);
          //不加载新建弹框
          this.load_creageBox = false;
        }
      },
    },
    computed: {
      ...mapState({
        NodeClassfiy: state => state.system.NodeClassfiy, //节点分类数据
        NodeClassfiyData: state => state.system.NodeClassfiyData, //节点分类Id
        personnelData: state => state.hr.personnelData, //企业所有员工信息
        show_billsCreateBox: state => state.billing.show_billsCreateBox, //控制显示新建弹框组件
        userPowerInfo: state => state.user.userPowerInfo, //权限
      })
    },
    methods: {
      ...mapMutations([
        'SHOW_BILLSCREATEBOX', //控制新建弹框是否显示
        'NODE_CLASSFIY_DATA' //保存节点分类信息
      ]),
      ...mapActions([
        'getAllNodeType', //获取节点分类数据
        'getUserPowerInfo', //获取用户操作权限
      ]),

      /* 判断vuex是否含有相关数据 */
      async judgeNoderelveData() {
        //判断用户权限(需优化  这里先这样写  不知道为什么await没有用)
        if (this.userPowerInfo.length == 0) {
          await this.getUserPowerInfo();
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 9, 1, '查询')) {
            return
          }
          this.isPower = true
        } else {
          //判断是否有查询权限
          if (this.commonJsExtend.isHaveThePower(22, 9, 1, '查询')) {
            return
          }
          this.isPower = true
        }
        if (this.NodeClassfiy.length == 0) {
          await this.getAllNodeType();
        }
        //默认获取销售列表
        this.getNodeListByType('SYS-NODE-TYPE-001');
      },

      /* 根据节点分类获取节点列表数据 */
      async getNodeListByType(nodeCode) {
        //循环获取当前所选节点分类
        this.NodeClassfiy.forEach((item, index) => {
          if (item.code == nodeCode) {
            this.nodeMsg.currentNodeType = item;
          }
        })
        //获取分类列表
        let data = {
          type_id: this.nodeMsg.currentNodeType.id
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#nodeList", 4, '节点列表信息获取中,请稍候...');
        //发送请求
        api.getNodeInfoByClassfiyId(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //清空先
            this.nodeData = [];
            //获取返回结果
            let nodeDate = res.data;
            //过滤完成
            nodeDate.forEach((item,index)=>{
              if(item.name != '完成'){
                this.nodeData.push(item);
              }
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 新增节点信息*/
      addNodeData() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(22, 9, 2, '新增')) {
          return
        }
        this.load_creageBox = true; //控制加载新建框
        this.SHOW_BILLSCREATEBOX(true); //打开弹框
        this.operateType = 0; //设置操作类型
      },

      /* 修改节点信息*/
      updateNodeData(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(22, 9, 4, '修改')) {
          return
        }
        //控制加载新建框
        this.load_creageBox = true;
        //打开弹框
        this.SHOW_BILLSCREATEBOX(true);
        //设置操作类型
        this.operateType = 1;
        //设置节点id
        this.nodeMsg.nodeId = data.id;
      },

      /* 删除节点信息*/
      deleteNodeData(data, index) {
        //判断是否有删除权限
        if (this.commonJsExtend.isHaveThePower(22, 9, 3, '删除')) {
          return
        }
        this.$confirm('确认删除该节点吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = this.commonJsExtend.customLoading("#nodeView", 2);
          api.deleteWorkflowSetupNode({
            id: data.id, //id
            name: data.name //名称
          }).then(res => {
            if (res.code == 200) {
              this.loading.close()
              this.$message({
                type: 'success',
                message: '删除成功!',
                duration: 1500
              });
              this.nodeData.splice(index, 1)
            } else if (res.code == 400) {
              this.loading.close()
              this.$message({
                type: 'error',
                message: res.message,
                duration: 1500
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
            duration: 1500
          });
        });
      },
    },
    components: {
      nodeSetupCreate
    }
  }
</script>

<!-- element ui自定义样式 -->
<style lang="less">
  .nodeSetupView {

    // 选项卡样式
    .el-tabs__content {
      height: 100%;

      // border: 1px solid red;
      .el-tab-pane {
        height: 100%;
        // border: 1px solid red;
      }
    }
  }
</style>
<style lang="less">
  .nodeSetupView {
    height: 100%;

    // border: 1px solid black;
    .nodeSetupBox {
      height: 100%;

      // border: 1px solid black;
      // 节点添加按钮
      .nodeSetupHead {
        height: 40px;
        line-height: 40px;
        // border: 1px solid black;
      }

      // 表格内容
      .nodeSetupBody {
        height: calc(100% - 40px);
        // border: 1px solid red;
      }
    }
  }

  /* 文本超出长度显示省略号 */
  .people {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
