<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：节点新建界面
	开始时间：2021-01-14
	开发人员：万攀
	最后修改：2021-01-14
	备注说明：如需修改请联系开发人员
-->
<template>
  <div id="nodeCreate" class="nodeCreateBox">
    <el-dialog :title="(operateType==0?'新增':'修改')+'节点'" :visible.sync="show_billsCreateBox"
      :before-close="colse_createBox" :fullscreen="true" id="nodeCreateBox">
      <div class="billingcreate">
        <!-- 节点表单信息 -->
        <div class="nodeFromMsg billingcreate_form">
          <ul class="form_ul">
            <li>
              <div class="form_title">
                <span class="mustHint">*</span>
                节点名称:
              </div>
              <div class="form_date">
                <input type="text" v-model="nodeData.nodeName" placeholder="请输入名称">
              </div>
            </li>
            <li>
              <div class="form_title">
                授权人员:
              </div>
              <div class="form_date" @click="getPowerUserData">
                <el-tooltip placement="bottom" :disabled="show_powerTip">
                  <!-- 提示信息框 -->
                  <div slot="content">
                    <ul>
                      <li v-for="elem in nodeData.powerUser">{{elem.user_name}}</li>
                    </ul>
                  </div>
                  <!-- 选择框 -->
                  <input disabled type="text" v-model="nodeData.powerUserStr" placeholder="选择...">
                </el-tooltip>
                <span>
                  <i class="el-icon-more"></i>
                </span>
              </div>
            </li>
            <li>
              <div class="form_title">
                协作人员:
              </div>
              <div class="form_date" @click="getJonUserData">
                <el-tooltip placement="bottom" :disabled="show_joinTip">
                  <!-- 提示信息框 -->
                  <div slot="content">
                    <ul>
                      <li v-for="elem in nodeData.joinUser">{{elem.user_name}}</li>
                    </ul>
                  </div>
                  <!-- 选择框 -->
                  <input disabled type="text" v-model="nodeData.joinUserStr" placeholder="选择...">
                </el-tooltip>
                <span>
                  <i class="el-icon-more"></i>
                </span>
              </div>
            </li>
            <li>
              <div class="form_title">
                <span class="mustHint">*</span>
                初审人员:
              </div>
              <div class="form_date" @click="getChargeUserData">
                <el-tooltip placement="bottom" :disabled="show_chargeTip">
                  <!-- 提示信息框 -->
                  <div slot="content">
                    <ul>
                      <li v-for="elem in nodeData.chargeUser">{{elem.user_name}}</li>
                    </ul>
                  </div>
                  <!-- 选择框 -->
                  <input disabled type="text" v-model="nodeData.chargeUserStr" placeholder="选择...">
                </el-tooltip>
                <span>
                  <i class="el-icon-more"></i>
                </span>
              </div>
            </li>
            <li>
              <div class="form_title">
                <el-checkbox v-model="nodeData.isHighLeval">是否复审</el-checkbox>
              </div>
              <div v-if="nodeData.isHighLeval" class="form_date" @click="getHighLevalUserData">
                <el-tooltip placement="bottom" :disabled="show_recheckTip">
                  <!-- 提示信息框 -->
                  <div slot="content">
                    <ul>
                      <li v-for="elem in nodeData.highLevelUser">{{elem.user_name}}</li>
                    </ul>
                  </div>
                  <!-- 选择框 -->
                  <input disabled type="text" v-model="nodeData.highLevelUserStr" placeholder="选择复审人员">
                </el-tooltip>
                <span>
                  <i class="el-icon-more"></i>
                </span>
              </div>
            </li>
            <li v-if="nodeData.isHighLeval">
              <div class="form_title">
                <el-checkbox v-model="nodeData.isFinalCheck">是否终审</el-checkbox>
              </div>
              <div v-if="nodeData.isFinalCheck" class="form_date" @click="getFinalCheckUserData">
                <el-tooltip placement="bottom" :disabled="show_finalTip">
                  <!-- 提示信息框 -->
                  <div slot="content">
                    <ul>
                      <li v-for="elem in nodeData.finalCheckUser">{{elem.user_name}}</li>
                    </ul>
                  </div>
                  <!-- 选择框 -->
                  <input disabled type="text" v-model="nodeData.finalCheckUserStr" placeholder="选择终审人员">
                </el-tooltip>
                <span>
                  <i class="el-icon-more"></i>
                </span>
              </div>
            </li>
            <li>
              <div class="form_title">
                <el-checkbox v-model="nodeData.isAgainst">是否反审</el-checkbox>
              </div>
              <div v-if="nodeData.isAgainst" class="form_date" @click="getAgainstData">
                <el-tooltip placement="bottom" :disabled="show_against">
                  <!-- 提示信息框 -->
                  <div slot="content">
                    <ul>
                      <li v-for="elem in nodeData.againstUser">{{elem.user_name}}</li>
                    </ul>
                  </div>
                  <!-- 选择框 -->
                  <input disabled type="text" v-model="nodeData.againstUserStr" placeholder="选择反审人员">
                </el-tooltip>
                <span>
                  <i class="el-icon-more"></i>
                </span>
              </div>
            </li>
            <li>
              <div class="form_title">
                工时类型:
              </div>
              <div class="form_date" @click="judgeShowDown('show_workHourTypeBox')">
                <input type="text" v-model="nodeData.workHourTypeName" disabled placeholder="选择...">
                <span>
                  <i class="el-icon-arrow-down"></i>
                </span>
                <!-- 下拉菜单 -->
                <ul class="pulldown_menu" v-show="show_downData.show_workHourTypeBox">
                  <li v-for="elem in workHourTypeData" @click="getWorkHourMsg(elem)">{{elem.name}}</li>
                </ul>
              </div>
            </li>
            <li>
              <div class="form_title">
                时长类型:
              </div>
              <div class="form_data_filed">
                <el-input v-show="nodeData.workHourType==1" maxlength="5" show-word-limit class="input_text"
                  v-model="nodeData.workHourNum" size="mini" placeholder="请输入数字"></el-input>
                <el-radio-group class="ragioGroup" v-model="nodeData.workHour">
                  <el-radio class="input_radio" :label="1">天</el-radio>
                  <el-radio class="input_radio" :label="2">小时</el-radio>
                </el-radio-group>
              </div>
            </li>
            <li v-show="operateType==0">
              <div class="form_title">
                节点模板:
              </div>
              <div class="form_date" @click="judgeShowDown('show_nodeTemple')">
                <input type="text" v-model="nodeData.nodeTemple" disabled placeholder="选择...">
                <span>
                  <i class="el-icon-arrow-down"></i>
                </span>
                <!-- 下拉菜单 -->
                <ul class="pulldown_menu" v-show="show_downData.show_nodeTemple">
                  <li v-for="(item,index) in parentNodeData" :key="item.id" @click="getNodeTemple(item)">{{item.name}}
                  </li>
                </ul>
              </div>
            </li>
            <!-- <li>
              <div class="form_title">
                <span class="mustHint">*</span>
                作用单据类别:
              </div>
              <div class="form_date" @click="judgeShowDown('show_billsClasify')">
                <input type="text" v-model="nodeData.nodeBillsClassify" disabled placeholder="选择...">
                <span>
                  <i class="el-icon-arrow-down"></i>
                </span>
                <ul class="pulldown_menu" v-show="show_downData.show_billsClasify">
                  <li v-for="(item,index) in currentBillClassify" :key="item.id" @click="getNodeClassify(item)">
                    {{item.name}}</li>
                </ul>
              </div>
            </li> -->
            <!-- <li v-show="operateType==0">
              <div class="form_title">
                添加到流程:
              </div>
              <div class="form_data_filed">
                <el-checkbox class="input_checkbox" v-model="is_addWork"></el-checkbox>
              </div>
            </li> -->
            <!-- <li v-show="operateType==1 && NodeClassfiyData==1">
              <div class="form_title">
                协作权限:
              </div>
              <div class="form_data_filed">
                <el-checkbox class="input_checkbox" v-model="show_setPowerUser" :disabled="nodeData.joinUserStr==''">
                  为参与人员添加用户</el-checkbox>
                <el-checkbox class="input_checkbox" v-model="show_setCustomerClassify">添加客户分类</el-checkbox>
              </div>
            </li> -->
            <!-- <li v-show="show_setCustomerClassify">
              <div class="form_title">
                <span class="mustHint">*</span>
                客户分类:
              </div>
              <div class="form_date">
                <el-tooltip placement="top" :content="customerClassfiyStr" :disabled="customerClassfiyStr==''">
                  <input type="text" v-model="customerClassfiyStr" placeholder="选择..."
                    @click="show_customerClassfiy = !show_customerClassfiy">
                </el-tooltip>
                <span @click="show_customerClassfiy = !show_customerClassfiy">
                  <i class="el-icon-arrow-down"></i>
                </span>
                <ul class=" pulldown_menu" v-show="show_customerClassfiy" style="height: 300px;">
                  <li v-for="(item,index) in clientClassfiy" :key="item.id" @click="addCustomerClassfiy(item)">
                    {{item.name}} <i class="el-icon-check" v-show="item.show_icon"></i>
                  </li>
                </ul>
              </div>
            </li> -->
          </ul>
          <!-- 协助参与人员选择 -->
          <!-- <div class="billingcreate_form particiPationBox" v-show="show_setPowerUser">
            <ul class="form_ul">
              <li v-for="(item,index) in addJoinUser" :key="index">
                <div class="form_title">
                  {{item.user_name}}:
                </div>
                <div class="form_date" @click="getAddJonUserData(item)">
                  <el-tooltip placement="bottom" :disabled="item.particiPationStr==''">
                    <div slot="content">
                      <ul>
                        <li v-for="elem in item.particiPation">{{elem.user_name}}</li>
                      </ul>
                    </div>
                    <input type="text" v-model="item.particiPationStr" placeholder="选择...">
                  </el-tooltip>
                  <span>
                    <i class="el-icon-more"></i>
                  </span>
                </div>
              </li>
            </ul>
          </div> -->
        </div>

        <!-- 选项卡分类 -->
        <div class="billingcreate_tab nodeTypeBox" id="nodeTypeBox">
          <el-tabs v-model="nodeTabIndex" type="border-card" style="height: 100%;">
            <!-- 基本信息 -->
            <el-tab-pane label="基本信息" name="1">
              <div class="nodeTypeItemBox">
                <el-tabs tab-position="left" style="height: 100%;">
                  <el-tab-pane label="系统数据">
                    <!-- 系统基本信息设置 -->
                    <systemFormSetup :nodeSystemFormData="nodeSystemFormData" @getNodeBaseData="getNodeBaseData"
                      :nodeMsg="nodeMsg" :operateType="operateType">
                    </systemFormSetup>
                  </el-tab-pane>
                  <el-tab-pane label="自定义数据">
                    <!-- 自定义基本信息设置 -->
                    <filedFormSetup :nodeCusFormData="nodeCusFormData" @getNodeCusBaseData="getNodeCusBaseData"
                      :nodeMsg="nodeMsg" :operateType="operateType">
                    </filedFormSetup>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </el-tab-pane>
            <el-tab-pane label="表体信息" name="2">
              <div class="nodeTypeItemBox">
                <el-tabs tab-position="left" style="height: 100%;">
                  <el-tab-pane label="系统数据">
                    <!-- 表格信息设置 -->
                    <systemTableSetup :nodeSystemTableData="nodeSystemTableData" @getNodeTableData="getNodeTableData"
                      :nodeMsg="nodeMsg" :operateType="operateType">
                    </systemTableSetup>
                  </el-tab-pane>
                  <el-tab-pane label="自定义数据">
                    <!-- 表格自定义信息设置 -->
                    <filedTableSetup :nodeCusTableData="nodeCusTableData" @getNodeCusTableData="getNodeCusTableData"
                      :nodeMsg="nodeMsg" :operateType="operateType">
                    </filedTableSetup>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <!-- 提交按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getCommitOperate">确认</el-button>
        <el-button type="success" @click="colse_createBox">取消</el-button>
      </span>
    </el-dialog>

    <!-- 负责人组件 -->
    <personnelList @SelectedData="getPersonnelData" :defaultSelected="defaultUserSelected"></personnelList>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions,
  } from 'vuex'

  //导入请求方法
  import api from "@/network/system/workflowSetup"; //流程模块接口

  // 导入组件
  import personnelList from '@/components/commComponent/list/list_personnel'; //负责人列表组件
  import systemFormSetup from './nodeSetupItem/systemFormSetup.vue'; //系统表单设置
  import systemTableSetup from './nodeSetupItem/systemTableSetup.vue'; //系统表格设置
  import filedFormSetup from './nodeSetupItem/filedFormSetup.vue'; //自定义表单数据
  import filedTableSetup from './nodeSetupItem/filedTableSetup.vue'; //自定义表格数据

  export default {
    name: 'nodeSetupCreate',
    props: {
      //操作类型(0: 新建 1:修改  2:查看)
      operateType: {
        type: Number,
        default: 0
      },
      //节点信息
      nodeMsg: {
        type: Object,
        default: {
          currentNodeType: {}, //当前所选节点分类数据
          nodeId: -1, //节点id
        }
      },
      //
      parentNodeData: {
        type: Array,
        default () {
          return []
        }
      },
    },
    data() {
      return {
        // 当前组件数据
        customerClassfiyStr: '', //客户分类字符串
        selectCustomerClassfiy: [], //选中的客户分类
        selectCustomerClassfiyId: [], //选中的客户分类的ID
        customerClassfiyStrId: '', //选中客户分类Id字符串
        clientClassfiy: [], //客户分类
        addJoinUser: [], //为参与人员赋予其他用户的客户
        currentItem: {}, //当前点击的协助人员
        nodeTitle: '', //节点弹框名称
        nodeTabIndex: "1", //节点tab选择下标
        oldNodeName: '', //旧的节点名称
        nodeData: { //节点数据
          nodeName: '', //节点名称
          powerUser: [], //授权人员
          powerUserStr: '', //已选授权人员字符串
          powerUserIdStr: '', //已选授权人员id字符串
          joinUser: [], //已选协作人员
          joinUserStr: '', //已选协作人员字符串
          joinUserIdStr: '', //协作人员id字符串
          chargeUser: [], //审批人员
          chargeUserStr: '', //审批人员字符串
          chargeUserIdStr: '', //审批人员id字符串
          isHighLeval: false, //是否复审(默认不开启)
          highLevelUser: [], //更高权限审核人
          highLevelUserStr: '', //更高权限审核人字符串
          highLevelUserIdStr: '', //更高权限审核人id字符串
          isFinalCheck: false, //是否终审
          finalCheckUser: [], //终审人员
          finalCheckUserStr: '', //终审人员字符串
          finalCheckUserIdStr: '', //终审人员id字符串
          isAgainst: false, //是否反审
          againstUser: [], //反审人员
          againstUserStr: '', //反审人员字符串
          againstUserIdStr: '', //反审人员id字符串
          workHourType: '', //工时类型(0:员工填写 1:公司规定  2:系统计算)
          workHourTypeName: '', //界面显示工时类型名称
          workHourNum: '', //时长数
          workHour: 1, //时长类型
          nodeBillsClassify: '', //作用单据名称
          nodeBillsClassifyId: '', //作用单据id
          nodeTemple: "", //节点模板
          customerClassfiy: '', //客户分类
        },
        selectUserType: -1, //选择人员类型(0:协作人员 1:审核人员 2:授权人员  3:授权人员 4:复审人员  5:终审人员 6:反审人员)
        sourceData: {}, //定义编辑前数据

        //控制参数
        show_powerTip: true, //是否显示授权人员提示框
        show_joinTip: true, //是否显示协作人员提示框
        show_chargeTip: true, //是否显示审核人员提示框
        show_recheckTip: true, //是否显示复审人员提示框
        show_finalTip: true, //是否显示终审人员提示框
        show_against: true, //是否显示反审人员提示框
        show_classfiy: true, //是否显示分类提示框
        show_setPowerUser: false, //是否显示为协作人员添加用户
        show_setCustomerClassify: false, //是否显示为协作人员添加客户分类
        show_customerClassfiy: false, //是否显示客户分类
        show_icon: false, //是否显示图标
        is_addWork: false, //是否添加到流程

        //传入子组件的数据
        defaultUserSelected: [], //已选用户

        //企业级基本信息数据
        nodeTargetObj: { //验证节点信息是否齐全
          nodeInfo: false, //节点表信息
          nodeBase: false, //节点基本信息是否合法
          nodeCusBase: false, //节点自定义基本信息是否合法
          nodeTable: false, //节点表格信息是否合法
          nodeCusTable: true, //节点自定义表格信息是否合法(该功能未开发,目前默认合法)
        },
        nodeBillData: { //节点表单配置信息
          nodeBaseInfo: {}, //节点表信息
          nodeBaseData: [], //节点基本信息
          nodeCusBaseData: [], //节点自定义基本信息
          nodeTableData: [], //节点表格信息
          nodeCusTableData: [], //节点自定义表格信息
        },

        //传入子组件的数据
        nodeSystemFormData: [], //节点表单信息(回显用)
        nodeSystemTableData: [], //节点表格信息(回显用)
        nodeCusFormData: [], //节点自定义表单信息(回显用)
        nodeCusTableData: [], //节点自定义表格信息(回显用)

        //控制开关
        show_downData: {
          show_workHourTypeBox: false, //是否显示工时类型选项框
          show_nodeTemple: false, //是否显示节点模板
          show_billsClasify: false, //是否显示作用单据
        },

        //辅助数据
        userStrLength: 2, //员工显示长度(默认显示两个)
        currentBillClassify: [], //当前模块单据分类

        //loading框
        loading: '', //loading

        //界面自定义数据
        workHourTypeData: [{ // 工时数据
            id: 0,
            name: '员工填写',
          },
          {
            id: 1,
            name: '公司规定',
          },
          {
            id: 2,
            name: '系统计算',
          }
        ],
      }
    },
    computed: {
      ...mapState({
        show_billsCreateBox: state => state.billing.show_billsCreateBox, //控制显示新建弹框组件
        personnelData: state => state.hr.personnelData, //企业所有员工信息
        NodeClassfiyData: state => state.system.NodeClassfiyData, //节点分类id
        CustomerClassfiy: state => state.system.CustomerClassfiy, //客户分类
        ControlCommitNode: state => state.system.ControlCommitNode, //控制是否提交当前节点信息数据
        billingClassifyData: state => state.system.billingClassifyData, //单据模块子级分类数据
      }),
    },
    created() {
    },
    mounted() {
      //初始化vuex数据
      this.initVuexData();
    },
    watch: {
      //监听是否触发提交基本信息数据
      ControlCommitNode(newVal) {
        //提交节点基本信息
        this.commitNodeBaseInfo();
      },
      //监听提交节点数据合法对象
      nodeTargetObj: {
        handler(newVal) {
          //提交节点方法
          this.commitNodeData();
        },
        deep: true
      }
    },
    methods: {
      ...mapMutations([
        'SHOW_BILLSCREATEBOX', //控制新建弹框是否显示
        'SHOW_PERSONNELBOX', //控制负责人选择弹框是否显示
        'CONTROL_COMMIT_NODE', //控制是否提交新增时的节点基本信息
      ]),
      ...mapActions([
        'getCustomerClassify', //获取客户分类
        'getAllUserData',//获取企业所有员工
      ]),

      /* 初始化vuex数据 */
      async initVuexData(){
        //判断客户分类
        if (this.CustomerClassfiy.length == 0) {
          await this.getCustomerClassify()
        }
        //判断企业用户
        if(this.personnelData.length == 0){
          await this.getAllUserData();
        }
        //初始化节点信息数据
        this.initUpdataNodeData();
      },

      //判断Vuex中数据是否为空,不为空则请求
      async judgeCrmClassfiy() {
        if (this.CustomerClassfiy.length == 0) {
          await this.getCustomerClassify()
        }
      },

      /* 获取节点默认数据 */
      getNodeDefaultData() {
        // 获取节点默认单据类型数据
        this.billingClassifyData.forEach((item, index) => {
          //根据code判断
          if (item.code == this.nodeMsg.currentNodeType.code) {
            this.currentBillClassify = item.data;
          }
        })
      },

      /* 判断显示哪个下拉框(下拉框互斥功能) */
      judgeShowDown(fileName) {
        for (let i in this.show_downData) {
          if (i == fileName) {
            this.show_downData[i] = !this.show_downData[i];
          } else {
            this.show_downData[i] = false;
          }
        }
      },

      /* 获取企业级节点基本信息数据(企业级) */
      getNodeBaseData(data) {
        //获取基本信息
        this.nodeBillData.nodeBaseData = data;
        //设置基本信息数据合法
        this.nodeTargetObj.nodeBase = true;
      },

      /* 获取企业级节点表格信息数据(企业级) */
      getNodeTableData(data) {
        //获取表格信息
        this.nodeBillData.nodeTableData = data;
        //设置表格信息数据合法
        this.nodeTargetObj.nodeTable = true;
      },

      /* 获取节点自定义表单信息(企业级) */
      getNodeCusBaseData(data) {
        this.nodeBillData.nodeCusBaseData = data;
        //设置自定义表单信息数据合法
        this.nodeTargetObj.nodeCusBase = true;
      },

      /* 获取节点自定义表格信息(企业级) */
      getNodeCusTableData() {
        //设置自定义表格信息数据合法
        this.nodeTargetObj.nodeCusTable = true;
      },

      /* 获取节点修改时的基本信息 */
      getUpdateNodeBaseData() {
        //加载loading框

        //根据当前id获取节点数据
        api.getWorkflowSetupNodeById({
          id: this.nodeMsg.nodeId
        }).then(res => {
          if (res.code == 200) {
            //保存旧的节点名称
            this.oldNodeName = res.data.node.name;
            //获取节点名称
            this.nodeData.nodeName = res.data.node.name;

            // 获取授权人员信息
            this.nodeData.powerUserIdStr = res.data.node.power_userids; //id字符串
            if (res.data.node.power_usernames) {
              this.nodeData.powerUser = this.getUserInfoByUserIdStr(res.data.node.power_userids); //授权人员名称数组
            }
            this.nodeData.powerUserStr = res.data.node.power_usernames; //授权人员名称字符串

            //获取协作人员信息
            this.nodeData.joinUserIdStr = res.data.node.join_userids; //协作人员id字符串
            if (res.data.node.join_usernames) {
              this.nodeData.joinUser = this.getUserInfoByUserIdStr(res.data.node.join_userids); //协作人员名称数组
            }
            this.nodeData.joinUserStr = res.data.node.join_usernames; //协作人员名称字符串

            //获取审批(初审)人员信息
            this.nodeData.chargeUserIdStr = res.data.node.charge_userids; //审批人员id字符串
            if (res.data.node.charge_userids) {
              this.nodeData.chargeUser = this.getUserInfoByUserIdStr(res.data.node.charge_userids); //审批人员名称数组
            }
            this.nodeData.chargeUserStr = res.data.node.charge_usernames; //审批人员名称字符串

            //获取复审人员信息
            this.nodeData.isHighLeval = res.data.node.is_recheck == 0 ? false : true
            this.nodeData.highLevelUserIdStr = res.data.node.high_level_userids; //审批人员id字符串
            if (res.data.node.high_level_userids) {
              this.nodeData.highLevelUser = this.getUserInfoByUserIdStr(res.data.node.high_level_userids); //复审人员名称数组
            }
            this.nodeData.highLevelUserStr = res.data.node.high_leval_usernames; //复审人员名称字符串

            //获取终审人员信息
            this.nodeData.isFinalCheck = res.data.node.is_final_check == 0 ? false : true
            this.nodeData.finalCheckUserIdStr = res.data.node.final_check_userids; //终审人员id字符串
            if (res.data.node.final_check_userids) {
              this.nodeData.finalCheckUser = this.getUserInfoByUserIdStr(res.data.node.final_check_userids); //终审人员名称数组
            }
            this.nodeData.finalCheckUserStr = res.data.node.final_check_usernames; //终审人员名称字符串

            //获取反审人员信息
            this.nodeData.isAgainst = res.data.node.is_against == 0 ? false : true
            this.nodeData.againstUserIdStr = res.data.node.against_userids; //反审批人员id字符串
            if (res.data.node.against_userids) {
              this.nodeData.againstUser = this.getUserInfoByUserIdStr(res.data.node.against_userids); //反审人员名称数组
            }
            this.nodeData.againstUserStr = res.data.node.against_useridnames; //反审人员名称字符串

            //工时类型
            this.nodeData.workHourType = res.data.node.working_type;
            this.workHourTypeData.forEach(item => {
              if (item.id == res.data.node.working_type) {
                this.nodeData.workHourTypeName = item.name;
              }
            })
            //工时时长数
            this.nodeData.workHourNum = res.data.node.working_hours;
            //工时时长类型
            this.nodeData.workHour = res.data.node.working_hours_type;
            //作用单据类型
            this.nodeData.nodeBillsClassifyId = res.data.type_classify;
            this.sourceData = JSON.parse(JSON.stringify(this.nodeData)) //将源数据储存
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 根据用户id字符串获取用户信息数组 */
      getUserInfoByUserIdStr(userIdStr){
        let userResult = [];
        if(!!userIdStr){
          //分割成数组
          let userIdArr = userIdStr.split(",");
          //根据用户id判断
          userIdArr.forEach((item,index)=>{
            this.personnelData.forEach((itemJ,indexJ)=>{
              if(parseInt(item) == itemJ.user_id){
                userResult.push(itemJ);
              }
            })
          })
        }
        return userResult;
      },

      /* 获取选择的节点模板信息 */
      getNodeTemple(data) {
        //获取模板名称
        this.nodeData.nodeTemple = data.name
        //根据模板名称获取数据
        this.getUpdateNodeBillsData(data.id);
      },

      /*初始化修改节点信息 */
      initUpdataNodeData() {
        //获取节点默认信息
        this.getNodeDefaultData();
        //根据操作类别获取其他信息
        if (this.operateType == 1) { //修改
          //获取节点基本信息数据
          this.getUpdateNodeBaseData();
          //获取节点表单信息数据
          this.getUpdateNodeBillsData(this.nodeMsg.nodeId);
          //设置除基本信息以外信息都合法
          this.nodeTargetObj = {
            nodeInfo: false, //节点表信息
            nodeBase: true, //节点基本信息是否合法
            nodeCusBase: true, //节点自定义基本信息是否合法
            nodeTable: true, //节点表格信息是否合法
            nodeCusTable: true, //节点自定义表格信息是否合法
          }
        }
      },

      // 获取修改时的表单数据信
      getUpdateNodeBillsData(id) {
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#nodeTypeBox", 4, '节点模本信息获取中,请稍候...');
        api.getAllNodeFromAndTable({
          status_id: id
        }).then(res => {
          this.loading.close()
          if (res.code == 200) {
            //获取表单信息
            this.nodeSystemFormData = res.data.nodeSysForm;
            //获取表格信息
            this.nodeSystemTableData = res.data.nodeSysTable;
            //获取表单自定义信息
            this.nodeCusFormData = res.data.nodeCustomForm;
            //获取表格自定义信息
            this.nodeCusTableData = res.data.nodeCustomTable;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 根据员工id查询员工相关信息*/
      getUserDataById(arr) {
        let arrData = [];
        this.personnelData.forEach(item => {
          if (arr.includes(item.user_id)) {
            arrData.push(item)
          }
        })
        let msg = this.getPersonnelMsg(arrData);
        // 定义返回值
        let returnMsg = {
          idStr: msg.idStr,
          nameStr: msg.nameStr,
          data: arrData
        }
        return returnMsg;
      },

      /* 获取参与人员*/
      getJonUserData() {
        this.selectUserType = 0;
        //传入员工选择组件的数据
        if (this.nodeData.joinUserIdStr) {
          this.defaultUserSelected = this.nodeData.joinUserIdStr.split(",");
        }
        this.SHOW_PERSONNELBOX(true);
      },

      /* 获取审批人员*/
      getChargeUserData() {
        this.selectUserType = 1;
        //传入员工选择组件的数据
        if (this.nodeData.chargeUserIdStr) {
          this.defaultUserSelected = this.nodeData.chargeUserIdStr.split(",");
        }
        this.SHOW_PERSONNELBOX(true);
      },

      /* 获取授权人员*/
      getPowerUserData() {
        this.selectUserType = 2;
        //传入员工选择组件的数据
        if (this.nodeData.powerUserIdStr) {
          this.defaultUserSelected = this.nodeData.powerUserIdStr.split(",");
        }
        this.SHOW_PERSONNELBOX(true);
      },

      //获取参与人员(授权人员)
      getAddJonUserData(data) {
        this.currentItem = data
        this.selectUserType = 3
        this.SHOW_PERSONNELBOX(true);
        this.defaultUserSelected = data.particiPation;
      },

      /* 获取复审人员 */
      getHighLevalUserData() {
        this.selectUserType = 4;
        //传入员工选择组件的数据
        if (this.nodeData.highLevelUserIdStr) {
          this.defaultUserSelected = this.nodeData.highLevelUserIdStr.split(",");
        }
        this.SHOW_PERSONNELBOX(true);
      },

      /* 获取终审人员 */
      getFinalCheckUserData() {
        this.selectUserType = 5;
        //传入员工选择组件的数据
        if (this.nodeData.finalCheckUserIdStr) {
          this.defaultUserSelected = this.nodeData.finalCheckUserIdStr.split(",");
        }
        this.SHOW_PERSONNELBOX(true);
      },

      /* 获取反审人员 */
      getAgainstData() {
        this.selectUserType = 6;
        //传入员工选择组件的数据
        if (this.nodeData.againstUserIdStr) {
          this.defaultUserSelected = this.nodeData.againstUserIdStr.split(",");
        }
        this.SHOW_PERSONNELBOX(true);
      },

      /* 获取工时类型*/
      getWorkHourMsg(data) {
        //工时类型id
        this.nodeData.workHourType = data.id;
        //工时类型名称
        this.nodeData.workHourTypeName = data.name;
        //清空时长数据
        this.nodeData.workHourNum = "";
      },

      /* 获取选中的作用单据类别 */
      getNodeClassify(data) {
        //获取名称
        this.nodeData.nodeBillsClassify = data.name;
        //获取id
        this.nodeData.nodeBillsClassifyId = data.id;
      },

      /* 触发子组件的提交方法 */
      getCommitOperate() {
        this.CONTROL_COMMIT_NODE(!this.ControlCommitNode);
      },

      /* 判断审核人员是否有冲突 */
      judgeCheckUserdifferent(){
        //定义人员数组
        let checkUserArr = [];
        let checkUserIdArr = [];
        //将初审人员加入
        this.nodeData.chargeUser.forEach((item,index)=>{
          checkUserArr.push(item);
          checkUserIdArr.push(this.nodeData.chargeUserIdStr.split(",")[index])
        })
        //将复审人员加入
        if(this.nodeData.isHighLeval){
          this.nodeData.highLevelUser.forEach((item,index)=>{
            checkUserArr.push(item);
            checkUserIdArr.push(this.nodeData.highLevelUserIdStr.split(",")[index])
          })
        }
        //将终审人员加入
        if(this.nodeData.isFinalCheck){
          this.nodeData.finalCheckUser.forEach((item,index)=>{
            checkUserArr.push(item);
            checkUserIdArr.push(this.nodeData.finalCheckUserIdStr.split(",")[index])
          })
        }
        //将反审人员加入
        if(this.nodeData.isAgainst){
          this.nodeData.againstUser.forEach((item,index)=>{
            checkUserArr.push(item);
            checkUserIdArr.push(this.nodeData.againstUserIdStr.split(",")[index])
          })
        }

        //获取重复人员
        let repetitionUser = [];//重复的员工id
        let repetitionUserInfo = [];//重复员工信息
        let norepetitionUser = [];//不重复员工
        checkUserIdArr.forEach((item,index)=>{
          if(norepetitionUser.includes(item)){
            if(!repetitionUser.includes(item)){
              repetitionUser.push(item);
              console.log(index);
              console.log(checkUserArr[index]);
              repetitionUserInfo.push(checkUserArr[index]);
            }
          }else{
            norepetitionUser.push(item);
          }
        })

        // 判断重复员工是否为系统管理员
        if(repetitionUserInfo.length>0){
          for(let i=0;i<repetitionUserInfo.length;i++){
            if(repetitionUserInfo[i].user_role!=1){
              this.$message({
                type: 'warning',
                message: "除系统管理员外,各审核人不能设置相同员工!",
                duration: this.elDuration
              })
              return false;
            }
          }
        }

        return true;
      },

      /* 提交基本节点基本信息数据 */
      commitNodeBaseInfo() {
        //判断节点名称是否合法
        if (this.nodeData.nodeName.trim().length > 20) {
          this.$message({
            type: 'warning',
            message: "节点名称长度不能大于20个字符!",
            duration: this.elDuration
          })
          return
        } else if (this.nodeData.nodeName.trim() == "") {
          this.$message({
            type: 'warning',
            message: "节点名称不能为空!",
            duration: this.elDuration
          })
          return
        }

        //判断授权人员是否合法
        // if (this.nodeData.powerUserIdStr == "") {
        //   this.$message({
        //     type: 'warning',
        //     message: "请选择授权人员!",
        //     duration: this.elDuration
        //   })
        //   return
        // }

        //判断审批人员
        if (this.nodeData.chargeUserIdStr == "") {
          this.$message({
            type: 'warning',
            message: "请选择初审人员!",
            duration: this.elDuration
          })
          return
        }

        //判断复审人员
        if (this.nodeData.highLevelUserIdStr == "" && this.nodeData.isHighLeval) {
          this.$message({
            type: 'warning',
            message: "请选择复审人员!",
            duration: this.elDuration
          })
          return
        }

        //判断终审人员
        if (this.nodeData.finalCheckUserIdStr == "" && this.nodeData.isFinalCheck) {
          this.$message({
            type: 'warning',
            message: "请选择终审人员!",
            duration: this.elDuration
          })
          return
        }

        //判断反审人员
        if (this.nodeData.againstUserIdStr == "" && this.nodeData.isAgainst) {
          this.$message({
            type: 'warning',
            message: "请选择反审人员!",
            duration: this.elDuration
          })
          return
        }

        //判断作用单据类别
        // if (this.nodeData.nodeBillsClassifyId == "" && this.operateType == 0) {
        //   this.$message({
        //     type: 'warning',
        //     message: "请选择作用单据类别!",
        //     duration: this.elDuration
        //   })
        //   return
        // }

        //判断选择的审核人员是否有相同的
        if(!this.judgeCheckUserdifferent()){
          return
        }

        // 获取后端接受参数
        this.nodeBillData.nodeBaseInfo = {
          name: this.nodeData.nodeName, //节点名称
          charge_userids: this.nodeData.chargeUserIdStr, //审核人员Id字符串
          charge_usernames: this.nodeData.chargeUserStr, //审核人员名称字符串
          join_userids: this.nodeData.joinUserIdStr, //参与人员Id字符串
          join_usernames: this.nodeData.joinUserStr, //参与人员名称字符串
          power_userids: this.nodeData.powerUserIdStr, //授权人员Id字符串
          power_usernames: this.nodeData.powerUserStr, //授权人员名称字符串
          is_recheck: this.nodeData.isHighLeval ? 1 : 0, //是否复审
          high_level_userids: this.nodeData.highLevelUserIdStr, //复审人员id字符串
          high_leval_usernames: this.nodeData.highLevelUserStr, //复审人员名称字符串
          is_final_check: this.nodeData.isFinalCheck ? 1 : 0, //是否终审
          final_check_userids: this.nodeData.finalCheckUserIdStr, //终审人员id字符串
          final_check_usernames: this.nodeData.finalCheckUserStr, //终审人员名称字符串
          is_against: this.nodeData.isAgainst ? 1 : 0, //是否反审
          against_userids: this.nodeData.againstUserIdStr, //反审人员id字符串
          against_useridnames: this.nodeData.againstUserStr, //反审人员名称字符串
          type_id: this.nodeMsg.currentNodeType.id, //节点分类Id
          working_hours_type: this.nodeData.workHour, //工时时长单位
          working_type: this.nodeData.workHourType, //工时类型
          working_hours: this.nodeData.workHourNum || '', //工时时长
        }

        //设置基本信息合法
        this.nodeTargetObj.nodeInfo = true;
      },

      /* 提交节点数据*/
      commitNodeData() {
        console.log(this.nodeTargetObj);
        //验证所有信息是否都合法
        for (let i in this.nodeTargetObj) {
          if (!this.nodeTargetObj[i]) {
            console.log(111);
            return
          }
        }

        // 定义一个后端接受的对象
        let nodeData = {
          sysNodeInfo: JSON.stringify(this.nodeBillData.nodeBaseInfo), //节点基本信息
          sysNodeSysForm: JSON.stringify(this.nodeBillData.nodeBaseData), //节点表单信息
          sysNodeSysTable: JSON.stringify(this.nodeBillData.nodeTableData), //节点表格信息
          sysNodeCustomForm: JSON.stringify(this.nodeBillData.nodeCusBaseData), //节点自定义表单信息
          sysNodeCustomTable: JSON.stringify(this.nodeBillData.nodeCusTableData), //节点自定义表格信息
        }

        if (this.operateType == 0) { //新增
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeCreateBox", 4, '节点信息添加中,请稍候...');
          //发送请求
          api.addNodeData(nodeData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '新增节点成功!',
                duration: this.elDuration
              })
              this.SHOW_BILLSCREATEBOX(false);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (this.operateType == 1) { //修改
          //日志
          let logContent = ''
          let objFiled = { //定义字典
            nodeName: '节点名称',
            powerUserStr: '授权人员',
            joinUserStr: '协作人员',
            chargeUserStr: '初审人员',
            highLevelUserStr: '复审人员',
            finalCheckUserStr:'终审人员',
            againstUserStr:'反审人员',
            workHourTypeName: '工时类型',
            workHour: '时长类型',
            workHourNum: '时长',
          }
          let keys = Object.keys(objFiled) //获取字段名
          keys.forEach(item => { //遍历key值进行匹配
            if (this.nodeData[item] != this.sourceData[item]) { //匹配出修改的字段
              logContent += '更改了【' + this.nodeData['nodeName'] + '】中的【' + objFiled[item] + '】,更改前:' //拼接公共部分
              //处理特殊字段
              if (item == 'workHour') { //当时长类型修改时
                logContent += (this.sourceData[item] == 1 ? '天' : (this.sourceData[item] == 2 ? '小时' : '无')) +
                  ",更改后:" + (this.nodeData[item] == 1 ? '天' : (this.nodeData[item] == 2 ? '小时' : '无')) +
                  ';' //拼接日志信息
              } else {
                logContent += this.sourceData[item] + ",更改后:" + this.nodeData[item] + ';' //拼接日志信息
              }
            }
          })
          //定义修改节点基本信息数据
          let updateNodeInfo = {
            id: this.nodeMsg.nodeId, //节点id
            name: this.nodeData.nodeName, //节点名称
            charge_userids: this.nodeData.chargeUserIdStr, //审核人员Id字符串
            charge_usernames: this.nodeData.chargeUserStr, //审核人员名称字符串
            join_userids: this.nodeData.joinUserIdStr, //参与人员Id字符串
            join_usernames: this.nodeData.joinUserStr, //参与人员名称字符串
            power_userids: this.nodeData.powerUserIdStr, //授权人员Id字符串
            power_usernames: this.nodeData.powerUserStr, //授权人员名称字符串
            is_recheck: this.nodeData.isHighLeval ? 1 : 0, //是否复审
            high_level_userids: this.nodeData.highLevelUserIdStr, //复审人员id字符串
            high_leval_usernames: this.nodeData.highLevelUserStr, //复审人员名称字符串
            is_final_check: this.nodeData.isFinalCheck ? 1 : 0, //是否终审
            final_check_userids: this.nodeData.finalCheckUserIdStr, //终审人员id字符串
            final_check_usernames: this.nodeData.finalCheckUserStr, //终审人员名称字符串
            is_against: this.nodeData.isAgainst ? 1 : 0, //是否反审
            against_userids: this.nodeData.againstUserIdStr, //反审人员id字符串
            against_useridnames: this.nodeData.againstUserStr, //反审人员名称字符串
            type_id: this.nodeMsg.currentNodeType.id, //节点分类Id
            working_hours_type: this.nodeData.workHour, //工时时长单位
            working_type: this.nodeData.workHourType, //工时类型
            working_hours: this.nodeData.workHourNum || '', //工时时长
            logContent //日志
          }
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#nodeCreateBox", 4, '节点信息修改中,请稍候...');
          //发送请求
          api.updateSystemNodeByNodeId(updateNodeInfo).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '节点修改成功!',
                duration: this.elDuration
              })
              this.SHOW_BILLSCREATEBOX(false);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 取消新建弹框 */
      colse_createBox() {
        let tip = "";
        if (this.operateType == 0) {
          tip = "您正在新建节点,确定要退出吗?"
        } else if (this.operateType == 1) {
          tip = "您正在编辑节点,确定要退出吗?"
        }
        this.$confirm(tip, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.SHOW_BILLSCREATEBOX(false)
        }).catch(() => {})
      },

      /*弹框信息*/
      getPersonnelData(data) { //获取选择的员工
        this.defaultUserSelected = []
        // 判断是什么人员
        if (this.selectUserType == 0) {
          //获取到删除的协作人员数据
          if (data.length == 0) { //长度为零关闭参与人员显示
            this.show_setPowerUser = false
          } else {
            this.show_setPowerUser = true
          }
          for (let i = 0; i < this.addJoinUser.length; i++) {
            let is_delect = false
            for (let j = 0; j < data.length; j++) {
              if (this.addJoinUser[i].user_name == data[j].user_name) {
                is_delect = true
                break;
              }
            }
            if (is_delect == false) {
              delete this.addJoinUser[i]
            }
          }
          this.addJoinUser = this.addJoinUser.filter((current, index, array) => {
            return current != undefined
          })
          //协作人员
          let newJoinUser = {}
          //获取到新增的协作人员数据
          for (let i = 0; i < data.length; i++) {
            let is_have = false
            for (let j = 0; j < this.nodeData.joinUser.length; j++) {
              if (data[i].user_name == this.nodeData.joinUser[j].user_name) {
                is_have = true
                break
              }
            }
            if (is_have == false) {
              newJoinUser = JSON.parse(JSON.stringify(data[i]))
              this.$set(newJoinUser, 'particiPationStr', '')
              this.$set(newJoinUser, 'particiPationIdStr', '')
              this.$set(newJoinUser, 'particiPation', [])
              this.addJoinUser.push(newJoinUser)
            }
          }

          //获取协作人员数组
          this.nodeData.joinUser = data;
          //获取协作人员数组字符串
          this.nodeData.joinUserIdStr = this.getPersonnelMsg(data).idStr;
          //获取协作人员显示字符串
          this.nodeData.joinUserStr = this.getPersonnelMsg(data).nameStr;
          // 判断是否需要显示提示框
          if (data.length > this.userStrLength) { //显示人员提示框
            this.show_joinTip = false;
          } else {
            this.show_joinTip = true;
          }
        } else if (this.selectUserType == 1) { //审批人员
          //获取审批人员数组
          this.nodeData.chargeUser = data;
          //获取审批人员数组字符串
          this.nodeData.chargeUserIdStr = this.getPersonnelMsg(data).idStr;
          //获取审批人员显示字符串
          this.nodeData.chargeUserStr = this.getPersonnelMsg(data).nameStr;
          // 判断是否需要显示提示框
          if (data.length > this.userStrLength) { //显示人员提示框
            this.show_chargeTip = false;
          } else {
            this.show_chargeTip = true;
          }
        } else if (this.selectUserType == 2) { //授权人员
          //获取授权人员数组
          this.nodeData.powerUser = data;
          //获取授权人员数组字符串
          this.nodeData.powerUserIdStr = this.getPersonnelMsg(data).idStr;
          //获取授权人员显示字符串
          this.nodeData.powerUserStr = this.getPersonnelMsg(data).nameStr;
          // 判断是否需要显示提示框
          if (data.length > this.userStrLength) { //显示人员提示框
            this.show_powerTip = false;
          } else {
            this.show_powerTip = true;
          }
        } else if (this.selectUserType == 3) { //为参与人员赋予其他用户的客户
          this.currentItem.particiPation = data
          this.currentItem.particiPationIdStr = this.getPersonnelMsg(data).idStr;
          this.currentItem.particiPationStr = this.getPersonnelMsg(data).nameStr;
        } else if (this.selectUserType == 4) { //复审人员
          //获取复审人员数组
          this.nodeData.highLevelUser = data;
          //获取复审人员数组字符串
          this.nodeData.highLevelUserIdStr = this.getPersonnelMsg(data).idStr;
          //获取复审人员显示字符串
          this.nodeData.highLevelUserStr = this.getPersonnelMsg(data).nameStr;
          // 判断是否需要显示提示框
          if (data.length > this.userStrLength) { //显示人员提示框
            this.show_recheckTip = false;
          } else {
            this.show_recheckTip = true;
          }
        } else if (this.selectUserType == 5) { //终审人员
          //获取终审人员数组
          this.nodeData.finalCheckUser = data;
          //获取终审人员数组字符串
          this.nodeData.finalCheckUserIdStr = this.getPersonnelMsg(data).idStr;
          //获取终审人员显示字符串
          this.nodeData.finalCheckUserStr = this.getPersonnelMsg(data).nameStr;
          // 判断是否需要显示提示框
          if (data.length > this.userStrLength) { //显示人员提示框
            this.show_finalTip = false;
          } else {
            this.show_finalTip = true;
          }
        } else if (this.selectUserType == 6) { //反审人员
          //获取反审人员数组
          this.nodeData.againstUser = data;
          //获取反审人员数组字符串
          this.nodeData.againstUserIdStr = this.getPersonnelMsg(data).idStr;
          //获取反审人员显示字符串
          this.nodeData.againstUserStr = this.getPersonnelMsg(data).nameStr;
          // 判断是否需要显示提示框
          if (data.length > this.userStrLength) { //显示人员提示框
            this.show_against = false;
          } else {
            this.show_against = true;
          }
        }
      },

      /* 根据所选员工获取相关信息 */
      getPersonnelMsg(data) {
        let idStr = "",
          nameStr = "";
        // 获取id字符串
        data.forEach((res, index) => {
          idStr = idStr + "," + res.user_id;
        })
        // 判断选择的人员有多少
        if (data.length > this.userStrLength) {
          data.forEach((res, index) => {
            if (index < this.userStrLength) {
              nameStr = nameStr + "," + res.user_name;
            }
          })
          nameStr = nameStr.substring(1, nameStr.length);
          // 超过定义信息显示提示信息
          nameStr = nameStr + "...等" + data.length + "人";
        } else {
          data.forEach((res, index) => {
            nameStr = nameStr + "," + res.user_name;
          })
          nameStr = nameStr.substring(1, nameStr.length);
        }
        // 定义返回参数
        let msg = {
          idStr: idStr.substring(1, idStr.length),
          nameStr: nameStr
        }
        return msg;
      },
    },
    components: {
      personnelList,
      systemFormSetup,
      systemTableSetup,
      filedFormSetup,
      filedTableSetup
    }
  }
</script>

<!-- element ui自定义样式 -->
<style lang="less">
  .nodeCreateBox {

    //新建弹框样式
    .nodeTypeBox {

      //横向分类tab框
      .el-tabs__content {
        height: calc(100% - 50px);
        // border: 1px solid red;
      }

      .el-tab-pane {
        height: 100%;
        // border: 1px solid black;
      }

      .nodeTypeItemBox {

        //纵向
        // border: 1px solid black;
        .el-tabs__content {
          height: 100%;
          // border: 1px solid yellow;
        }

        .el-tab-pane {
          height: 100%;
          // border: 1px solid black;
        }
      }
    }

    .el-checkbox__label {
      font-size: 12px !important;
    }
  }
</style>
<style lang="less">
  //新建节点
  .nodeCreateBox {

    //只起分类作用,不设定样式
    // 节点表单信息
    .nodeFromMsg {

      // border: 1px solid black;
      .particiPationBox {
        width: 90%;
        margin: 0px auto;
        box-shadow: 0px 0px 2px 1px gray;
      }
    }

    // 节点分类选项卡
    .nodeTypeBox {
      //横向选项卡
      height: 65vh;
      padding: 5px;

      // border: 1px solid blue;
      .nodeTypeItemBox {
        //纵向选项卡
        height: 100%;

        // border: 1px solid yellow;
        // 系统内容
        .systemBox {
          height: 100%;
          // border: 1px solid black;
        }

        // 自定义内容
        .filedBox {
          height: 100%;

          // border: 1px solid black;
          .filedHead {
            //头部新增按钮
            height: 40px;
            line-height: 40px;
            // border: 1px solid black;
          }

          .filedBody {
            //表格内容
            height: calc(100% - 40px);
            // border: 1px solid black;
          }
        }
      }
    }
  }
</style>
